<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link class="brand-logo">
          <b-img
              :src="appLogoImage"
              style="max-height: 100px"
              alt="logo"
          />
        </b-link>
        <div v-if="display_tab==='password'">
          <b-card-title class="mb-1">
            {{ $t('reset_password.card_title') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('reset_password.card_text') }}
          </b-card-text>
          <validation-observer ref="simpleRules">
            <b-form
              method="POST"
              class="auth-reset-password-form mt-2"
              @submit.prevent="validationForm"
            >

              <!-- password -->
              <b-form-group>
                <label for="new_password"><span class="text-danger text-bold">*</span> {{ $t('reset_password.new_password') }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="errors[0]" class="text-danger">{{ $t('reset_password.error_message') }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
              >
                <label for="new_password"><span class="text-danger text-bold">*</span> {{ $t('reset_password.new_password') }}</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="errors[0]" class="text-danger">{{ $t('reset_password.password_doesnt_match') }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                block
                type="submit"
                variant="primary"
              >
                {{ $t('reset_password.btn_ok') }}
              </b-button>
            </b-form>
          </validation-observer>

        </div>
        <div v-if="display_tab==='token_not_found'">
          <b-card-title class="mb-1 text-center text-danger">
            {{ $t('reset_password.not_found_token') }}
          </b-card-title>
          <b-card-text class="text-center mt-2">
            <b-link :to="{name:'auth-login-v1'}">
              <feather-icon icon="ChevronLeftIcon" /> {{ $t('reset_password.return_home') }}
            </b-link>
          </b-card-text>
        </div>
        <!-- form -->

        <!--        <p class="text-center mt-2">-->
        <!--          <b-link :to="{name:'auth-login-v1'}">-->
        <!--            <feather-icon icon="ChevronLeftIcon" /> Back to login-->
        <!--          </b-link>-->
        <!--        </p>-->

      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BImg,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import {$themeConfig} from "@themeConfig";

export default {
  components: {
    BImg,
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      display_tab: 'password',
      token: null,
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    this.token = this.$route.query.token
    this.checkToken()
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    checkToken() {
      if (this.token !== null) {
        useJwt.checkForgetPasswordTokenEndpoint({
          reset_password_token: this.token,
        })
          .then(response => {
            this.display_tab = 'password'
          })
          .catch(error => {
            this.display_tab = 'token_not_found'
            console.log(error.response.data)
          })
      } else {
        this.display_tab = 'token_not_found'
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          useJwt.setForgetPasswordEndpoint({
            reset_password_token: this.token,
            password: this.password,
          })
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('reset_password.toast_success_title'),
                  icon: 'MailIcon',
                  variant: 'success',
                  text: this.$t('reset_password.toast_success_text'),
                },
              })
              this.$router.push({ name: 'auth-login' })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.error'),
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
